#tsparticles {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.header {
    width: 100%;
    height: 600px;
    /*background: linear-gradient(to right, #093637, #468a7c);*/
    /*background: linear-gradient(to bottom, #380036, #0CBABA);*/
    /*background: linear-gradient(to bottom, #380036, #12b3be);*/
    background: linear-gradient(to right, #00243b, #12b3be);
    position: relative;
    overflow: hidden;
}

.header h1 {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -120%);
    text-align: center;
    padding-bottom: 5px;
    font-family: 'Raleway', sans-serif;
}

@media (max-width: 768px) {
  .header h1 {
    transform: translate(-50%, -110%);
    }
}

@media (max-width: 556px) {
  .header h1 {
    transform: translate(-50%, -90%);
    }
}

.header small {
    display: block;
    padding-top: 5px;
    font-size: 25px;
}

.wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: url(../images/wave.png);
    background-size: 1000px 100px;
}

.wave1 {
    animation: animate 30s linear infinite;
    z-index: 1000;
    opacity: 1;
    animation-delay: 0s;
    bottom: 0;
}

@keyframes animate
{
    0%
    {
        background-position-x: 0;
    }
    100%
    {
        background-position-x: 1000px;
    }
}

.wave2 {
    animation: animate2 15s linear infinite;
    z-index: 999;
    opacity: 0.5;
    animation-delay: -5s;
    bottom: 10px;
}

@keyframes animate2
{
    0%
    {
        background-position-x: 0;
    }
    100%
    {
        background-position-x: -1000px;
    }
}

.wave3 {
    animation: animate2 30s linear infinite;
    z-index: 998;
    opacity: 0.2;
    animation-delay: -2s;
    bottom: 15px;
}

.wave4 {
    animation: animate2 5s linear infinite;
    z-index: 997;
    opacity: 0.7;
    animation-delay: -5s;
    bottom: 15px;
}