.contact-text {
  text-align: center;
  margin-bottom: 70px;
  margin-top: 65px;
}

.contact-links {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 50px
}

@media (max-width:600px) {
  .contact-links {
    height: auto;
    flex-direction: column;
  }
  .contact-link {
    margin-bottom: 40px
  }
}

.contact-link {
  text-align: center;
}

.contact-link a {
  text-decoration: none;
  /*color: #2c6459*/
  /*color: #1a0038;*/
  color: #00243b;
}

.contact-link a:hover {
  /*color: #328373*/
  color: #0CBABA;
}

.fa {
  margin-bottom: 10px;
}