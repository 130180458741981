.nav-item {
    margin-left: 30px;
    margin-right: 30px;
}

.nav-link {
    cursor: pointer;
    color: white !important;
}

.navigation {
    /*background: linear-gradient(to right, #380036, #0CBABA) !important;*/
    /*background: #00243b;*/
    background: linear-gradient(to right, #00243b, #12b3be);
}