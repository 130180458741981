.cards-text {
    text-align: center;
    margin-top: 65px;
}

.card-box {
    width: 100%;
    height: 300px;
    margin: 50px auto 0;
    text-align: center;
}

.card-box .box {
    display: inline-block;
    position: relative;
    width: calc(400px - 30px);
    height: auto;
    background: #000;
    margin: 15px;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0,0,0,.8);
}

.card-box .box:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    box-sizing: border-box;
    transition: 0.5s;
    transform: scaleX(0);
    opacity: 0;
    z-index: 1;
}

.card-box .box:hover:before {
    transform: scaleX(1);
    opacity: 1;
}

.card-box .box::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    box-sizing: border-box;
    transition: 0.5s;
    transform: scaleY(0);
    opacity: 0;
    z-index: 1;
}

.card-box .box:hover::after {
    transform: scaleY(1);
    opacity: 1;
}

.card-box .box .imgBox {
    position: relative;
}

.card-box .box .imgBox img {
    width: 100%;
    transition: 0.5s;
}

.card-box .box:hover .imgBox img {
    opacity: .2;
    transform: scale(1.2);
}

.card-box .box .card-content {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.card-box .box .card-content h6 {
    margin: 0 0 10px;
    padding: 0;
    color: #fff;
    transition: 0.5s;
    transform: translateY(-50px);
    opacity: 0;
    visibility: hidden;
}

.card-box .box .card-content p {
    margin: 0;
    padding: 0;
    color: #fff;
    transition: 0.5s;
    transform: translateY(-50px);
    opacity: 0;
    visibility: hidden;
}

.card-box .box:hover .card-content h6, .card-box .box:hover .card-content p {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition-delay: 0.2s;
}

@media (max-width: 1200px) {
    .card-box {
        width: 800px;
        height: auto;
        margin: 80px auto 80px;
    }
    .card-box .box {
        width: calc(400px - 30px);
        height: auto;
    }
}

@media (max-width: 800px) {
    .card-box {
        width: 600px;
        height: auto;
        margin: 80px auto 80px;
    }
    .card-box .box {
        width: calc(300px - 30px);
        height: auto;
    }
}

@media (max-width: 600px) {
    .card-box {
        width: 100%;
        height: auto;
        margin: 80px auto 80px;
    }
    .card-box .box {
        width: calc(300px - 30px);
        height: auto;
        margin: 15px auto;
        float: none;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .card-box {
        margin-bottom: 200px;
    }
}

.line-break {
    display: block;
}

.card-content p {
    font-size: 14px;
}

@media (max-width:800px) {
    .login-account {
        display: none;
    }
}

.django-link {
    margin-top: 15px;
}

@media (max-width:800px) {
    .django-link {
        margin-top: 0px;
    }
}

.js-link {
    margin-top: 15px;
}

@media (max-width:800px) {
    .js-link {
        margin-top: 0px;
    }
}

.react-link {
    margin-top: 15px;
}

@media (max-width:800px) {
    .react-link {
        margin-top: 0px;
    }
}

.btn-info {
    background-color: #0d818a !important;
    outline: none;
    border: none;
}