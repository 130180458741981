.skills-table {
    display: flex;
}

.skills-text {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px;
}

.section {
    flex: 1;
    text-align: center;
    border-left: 0.5px solid rgb(39, 104, 93);
    border-right: 0.5px solid rgb(39, 104, 93);
    margin-bottom: 50px;
}

.section h2 {
    padding-bottom: 15px;
}

.section li {
    padding-top: 10px;
    list-style-type: none;
    text-align: center;
}

.section:first-child {
    border-left: none;
}

.section:last-child {
    border-right: none;
}

@media (max-width: 768px) { 
    .skills-table {
    flex-direction: column;
    }
}
